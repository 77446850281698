import { combineReducers } from 'redux';
import testReducer from './Test/reducer';
import candidateReducer from './Candidate/reducer';



const rootReducer = combineReducers({
    testReducer, 
    candidateReducer,
});

export default rootReducer;