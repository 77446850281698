import Summary from "../Pages/Summary/Summary";
import StartTest from "../Pages/Start Test/StartTest";
import EnterDetails from "../Pages/Enter Details/EnterDetails";
import NotAccessible from "../Not Accessible/NotAccessible";
import WhoWeAre from "../Pages/Who We Are/WhoWeAre";
import Overview from "../Pages/Overview/Overview";
import Review from "../Pages/Review/Review";
import Home from "../Pages/Home/Home";

const publicRoutes = [
	{ path: "/", component: WhoWeAre },
	{ path: "/:userId/:testDetails", component: Summary },
];

const authProtectedRoutes = [
	{ path: "/enter-details", component: EnterDetails },
	{ path: "/start-test", component: StartTest },
	{ path: "/notaccessible", component: NotAccessible},

 

];

const authProtectedRoutesFull = [

    { path: "/overview", component: Overview, fullScreen: true },
    { path: "/review", component: Review, fullScreen: true },

];


export { publicRoutes, authProtectedRoutes, authProtectedRoutesFull };
