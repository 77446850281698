import React, { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { publicRoutes, authProtectedRoutes, authProtectedRoutesFull } from "./routes/index";
import { useSelector } from 'react-redux';

import './App.css';
import {
  AlertTriangle
} from './Common/CommonImages';

function App() {
  const testId = useSelector((state) => state.testReducer?.name);
  const screen = useSelector((state) => state.testReducer?.screen);
  const mode = useSelector((state) => state.candidateReducer?.mode);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [showModal, setShowModal] = useState(false); // State to control the modal


  const enterFullScreen = () => {
    const element = document.documentElement;
    if (element.requestFullscreen) {
      element.requestFullscreen().then(() => {
        setIsFullscreen(true);
      }).catch((err) => {
        console.error("Error attempting to enter fullscreen mode:", err);
      });
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen().then(() => {
        setIsFullscreen(true);
      }).catch((err) => {
        console.error("Error attempting to enter fullscreen mode:", err);
      });
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen().then(() => {
        setIsFullscreen(true);
      }).catch((err) => {
        console.error("Error attempting to enter fullscreen mode:", err);
      });
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen().then(() => {
        setIsFullscreen(true);
      }).catch((err) => {
        console.error("Error attempting to enter fullscreen mode:", err);
      });
    }

  };

  const exitFullScreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen().then(() => {
        setIsFullscreen(false);
      }).catch((err) => {
        console.error("Error attempting to exit fullscreen mode:", err);
      });
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen().then(() => {
        setIsFullscreen(false);
      }).catch((err) => {
        console.error("Error attempting to exit fullscreen mode:", err);
      });
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen().then(() => {
        setIsFullscreen(false);
      }).catch((err) => {
        console.error("Error attempting to exit fullscreen mode:", err);
      });
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen().then(() => {
        setIsFullscreen(false);
      }).catch((err) => {
        console.error("Error attempting to exit fullscreen mode:", err);
      });
    }
  };

  useEffect(() => {
    if (screen && isFullscreen) {
      enterFullScreen();
      setShowModal(false);
    } else if(screen) {
      
      setShowModal(true);
      enterFullScreen();
     
    }
  }, [screen, isFullscreen]);



  useEffect(() => {
    if(mode && !isFullscreen){
      const handleFullScreenChange = () => {
        setIsFullscreen(document.fullscreenElement !== null);
      };
  
      document.addEventListener('fullscreenchange', handleFullScreenChange);
      document.addEventListener('mozfullscreenchange', handleFullScreenChange);
      document.addEventListener('webkitfullscreenchange', handleFullScreenChange);
      document.addEventListener('msfullscreenchange', handleFullScreenChange);
      setIsFullscreen(false);
      return () => {
        document.removeEventListener('fullscreenchange', handleFullScreenChange);
        document.removeEventListener('mozfullscreenchange', handleFullScreenChange);
        document.removeEventListener('webkitfullscreenchange', handleFullScreenChange);
        document.removeEventListener('msfullscreenchange', handleFullScreenChange);
        setIsFullscreen(false);
      };
    }
  }, []);


  return (
    <Router>
      <Routes>
        {publicRoutes.map((route, idx) => (
          <Route
            key={idx}
            path={route.path}
            element={<route.component />}
          />
        ))}
        {authProtectedRoutes.map((route, idx) => (
          <Route
            key={idx}
            path={route.path}
            element={(testId ? <route.component /> : <Navigate to="/" />)}
          />
        ))}
        {authProtectedRoutesFull.map((route, idx) => (
          <Route
            key={idx}
            path={route.path}
            element={testId ? <route.component /> : <Navigate to="/" />}
          />
        ))}
        {!testId && <Route path="/*" element={<Navigate to="/" />} />}
      </Routes>

      <Modal isOpen={showModal} onClose={() => { setShowModal(false); exitFullScreen(); }} className="alert-model">
        <div className="modal-content">
          <button type="button" className="btn-close" aria-label="Close" onClick={() => { setShowModal(false); exitFullScreen(); }}></button>
          <ModalBody>
            <div className='alert-trangle-text'>
              <img src={AlertTriangle} alt="" />
              Do not exit the full screen.
            </div>
          </ModalBody>
          <ModalFooter className="review-footer">
            <Button color="secondary" onClick={() => { setShowModal(false); exitFullScreen(); }} className='cancelModel'>Close</Button>
            <Button className='btn-end-test' onClick={() => { setShowModal(false); enterFullScreen(); }}>Resume </Button>
          </ModalFooter>
        </div>
      </Modal>

    </Router>
  );
}

export default App;
