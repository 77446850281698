
import React, { useState, useEffect, useRef } from 'react'
import {
  logo, testSheet, WhoWeAreImgpowerd, Calender, grayClock, grayIcon, userImage,
  dummyuserImage, greenCheck, CameraIcon, uploadProfile, uploadIcon, statTestIcon, AlertTriangle
} from '../../Common/CommonImages';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FormControl, Checkbox, Button, Box } from '@mui/material';
import { setFullScreen } from '../../store/Test/action';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setCandidateMode, setCandidateImage } from '../../store/Candidate/action';
import Webcam from "react-webcam";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
const videoConstraints = {
  width: 1280,
  height: 720,
  facingMode: "user"
};




function StartTest() {
  const testName = useSelector((state) => state.testReducer.name);
  const testTime = useSelector((state) => state.testReducer.time);
  const testDate = useSelector((state) => state.testReducer.date);
  const setting = useSelector((state) => state.testReducer.setting);
  const [stream, setStream] = useState(null); // State to hold the camera stream object
  const [cameraStarted, setCameraStarted] = useState(false); // State to track if the camera has started
  const webcamRef = useRef(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [showWebcam, setShowWebcam] = useState(false);
  const [isSharing, setIsSharing] = useState(false);
  const [timer, setTimer] = useState(3);
  const [ShowTimer, setShowTimer] = useState(false);
  const [mediaStream, setMediaStream] = useState(null);
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => setShowModal(!showModal);

  useEffect(() => {
    dispatch(setFullScreen(false));
    return () => {
      stopCamera();
    };

  }, []);

  const startCamera = () => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia({ video: true })
        .then(stream => {
          console.log('Camera started');
          setStream(stream);
          setCameraStarted(true);
        })
        .catch(error => {

          toast.error('camera access is disabled from browser');
          console.error('Error starting camera:', error);
        });
    } else {
      console.error('getUserMedia not supported');

    }
  };


  const stopCamera = () => {
    if (stream) {
      stream.getTracks().forEach(track => track.stop()); // Stop all tracks in the stream
      setStream(null);
      setCameraStarted(false);
      console.log('Camera stopped');
    } else {
      console.log('No camera stream to stop');
    }
  };

  useEffect(() => {
    // Check if webcamRef is initialized before attempting to call getScreenshot
    if (webcamRef.current) {
      capture(); // Capture the initial snapshot
    }
  }, []);


  const capture = () => {
    if (webcamRef.current) {
      const newImageSrc = webcamRef.current.getScreenshot();
      setImageSrc(newImageSrc);
      dispatch(setCandidateImage(newImageSrc));
    }
  };
 

  const retakePicture = () => {
    setShowTimer(true);
    setShowWebcam(true);
    setImageSrc(null);
    dispatch(setCandidateImage(null));
    let timer = 3; // Initialize timer to 3

    const intervalId = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer === 0) {
          clearInterval(intervalId);
          return 3;
        } else {
          return prevTimer - 1;
        }
      });
    }, 1000); // Update timer every second

    setTimeout(() => {
      clearInterval(intervalId); // Clear the interval when timeout occurs
      capture(); // Capture a new snapshot
      setTimer(3);
      setShowTimer(false);
    }, 3000);
  };

  const savePicture = () => {
    // Save logic here
    setShowWebcam(false);
  };


  const snapshotSettingFull = setting.find(setting => setting.setting === 'Turn on fullscreen while test');
  const snapshotSetting = setting.find(setting => setting.setting === 'Take Snapshots via webcam every 30 second');
  const screenSetting = setting.find(setting => setting.setting === 'Take screen snapshots on tab switch');
  const navigate = useNavigate();
  const handleEnterDetails = () => {
    navigate('/enter-details')
  }
  const handleOverview = () => {

    if ((snapshotSettingFull && snapshotSettingFull.active && !isSharing) || (snapshotSetting && snapshotSetting.active && !cameraStarted)) {
      toggleModal();
    } else {
      dispatch(setCandidateMode(true))
      navigate('/overview');
    }

    // if () {
    //   toggleModal();
    // }

    //
  }

  const grayClockText = {
    color: '#535A5F',
  };

  const checkboxStyle = {
    color: '#FF6812',
    width: '50px',
    height: '50px',
  };

  const checkboxStartTest = {
    color: '#FF6812',
    width: '32px',
    height: '32px',
  };

  const startScreenShare = async () => {
    try {
      const stream = await navigator.mediaDevices.getDisplayMedia({ video: true });
      setMediaStream(stream);
      setIsSharing(true);
    } catch (error) {
      console.error('Error sharing screen:', error);
    }
  };

  const stopScreenShare = () => {
    if (mediaStream) {
      mediaStream.getTracks().forEach(track => track.stop());
      setMediaStream(null);
      setIsSharing(false);
    }
  };

  const [checkboxChecked, setCheckboxChecked] = useState(false);


  const [termsChecked, setTermsChecked] = useState(false);
  const [privacyChecked, setPrivacyChecked] = useState(false);

  const handleTermsChange = (event) => {
    const isChecked = event.target.checked;
    setTermsChecked(isChecked);
    // If terms checkbox is checked, also check the privacy checkbox
    if (isChecked) {
      setPrivacyChecked(true);
    }
  };

  const handleCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    setCheckboxChecked(isChecked)
    setPrivacyChecked(isChecked);
    // If privacy checkbox is checked, also check the terms checkbox
    if (isChecked) {
      setTermsChecked(true);
    }
  };



  return (
    <>
      <div className="App">
        <ToastContainer />
        <div className="main-head">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <header className="logo-header">
                  <a href='/'><img src={logo} alt="logo" className='hire-logo' /></a>
                </header>
              </div>
            </div>
          </div>
        </div>

        <div className="progress-steps">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">

                <div className="progress-steps-inner">
                  <a href='/'><img src={logo} alt="logo" className='desktop-logo' /></a>
                  <ul className="timeline">
                    <li className="active completed-check"><span>Summary</span></li>
                    <li className="active completed-check"><span>Enter Details</span></li>
                    <li className="active "><span>Start Test</span></li>
                  </ul>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div className="middle-steps-content">

          <div className="max-container-middle-text">

            <div className="row">

              <div className="col-lg-12">

                <div className='middle-steps-inner-content'>
                  <div className='row middle-info-item'>

                    <div className='col-lg-12'>

                      <div className='practice-test-left'>
                        <div className='practice-test-name'>
                          <img src={testSheet} alt="Test Sheet Icon" className='test-sheet-img' />
                          <h1>{testName}</h1>
                        </div>
                        <span className='claender'><img src={Calender} alt="" /> {testDate.startDate} - {testDate.endDate}</span>
                        <p style={grayClockText}><img src={grayClock} alt="" />{testTime} hrs</p>
                      </div>

                    </div>

                  </div>

                  <div className="row">
                    <div className="col-lg-12">
                      <div className='test-description'>

                        <div className='user-test-required'>

                          <div className='user-test-info'>

                            {!cameraStarted ? (
                              <img src={userImage} alt="Test Sheet Icon" className='userimage' />
                            ) : (
                              <Webcam
                                audio={false}
                                className="userimage"
                                screenshotFormat="image/jpeg"
                                videoConstraints={videoConstraints}
                              />
                            )}

                            <div>
                              <h2>Why Camera?</h2>
                              {!cameraStarted ? (
                                <img src={grayIcon} alt="Test Sheet Icon" className='greencheck' />
                              ) : (
                                <img src={greenCheck} alt="Test Sheet Icon" className='greencheck' />
                              )}
                              <p>This test requires webcam access for proctoring snapshots will be taken in every 30secs.</p>
                              {!cameraStarted ? (
                                <Button className='startcamera' onClick={startCamera}>Start Camera</Button>
                              ) : (
                                <Button className='stopcamera' onClick={stopCamera}>Stop Camera</Button>
                              )}
                            </div>
                          </div>

                          <div className='user-test-info'>
                            {!imageSrc ? (
                              <img src={dummyuserImage} alt="Captured Image" className='userimage' />
                            ) : (
                              <img src={imageSrc} alt="Captured Image" className='userimage' />
                            )}
                            <div>
                              <h2>Profile Picture</h2>
                              {!imageSrc ? (
                                <img src={grayIcon} alt="Test Sheet Icon" className='greencheck' />
                              ) : (
                                <img src={greenCheck} alt="Test Sheet Icon" className='greencheck' />
                              )}
                              <p>Make sure your picture is neat and clean because the picture is going to be used throughout the recruitment process.</p>
                              <Button className='startcamera' data-bs-toggle="modal" data-bs-target="#cameraModal">{imageSrc ? 'Retake Picture' : 'Take Picture'}</Button>
                            </div>
                          </div>

                        </div>

                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-12">
                      <div className='test-description'>
                        <h2>Present Screen</h2>
                        {!isSharing ? (
                          <img src={grayIcon} alt="Test Sheet Icon" className='greencheck' />
                        ) : (
                          <img src={greenCheck} alt="Test Sheet Icon" className='greencheck' />
                        )}

                        <p>This test requires your entire screen to be shared for proctoring. If you share single tab or window you will be marked as absent and get eliminated from recruitment process.</p>
                        {!isSharing ? (
                          <Button className='startcamera' onClick={startScreenShare}>Start Screen Share</Button>
                        ) : (
                          <Button className='stopcamera' onClick={stopScreenShare}>Stop Screen Share</Button>
                        )}


                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-12">
                      <div className='test-description'>
                        <h2>Test Instruction</h2>
                        <ul>
                          <li>Welcome the candidate and provide a brief overview of the coding test.</li>
                          <li>Mention the expected duration of the test.</li>
                          <li>Clearly state the objective of the coding test.</li>
                          <li>Specify the problem or task that the candidate is expected to solve.</li>
                          <li>Indicate the programming language(s) and any specific tools or frameworks that should be used for the test.</li>
                          <li>If applicable, provide instructions for setting up the development environment.</li>
                          <li>Specify any dependencies or libraries that need to be installed.</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

              </div>

              <div className="col-lg-12">
                <div className='start-test-page'>
                  <FormControl className='privacy-policy-check'>
                    <Checkbox
                      onChange={handleCheckboxChange}
                      checked={privacyChecked}
                    />
                    <p className='check-text'>I agree to the privacy policy and terms of Use of EliteQA</p>
                  </FormControl>
                  <div className='start-test-flex'>
                    <Button onClick={handleEnterDetails} className='back'>Back</Button>
                    <Button data-bs-toggle="modal" data-bs-target="#startTestModel" disabled={!checkboxChecked} className='start-test-btn'>Start Test</Button>
                  </div>
                </div>
              </div>

              <div className="col-lg-12">
                <div className='footer-logo'>
                  <img src={WhoWeAreImgpowerd} alt="" className='who-wer-are-p-img' />
                </div>
              </div>

            </div>

          </div>

        </div>


        {/* cameraModal One */}

        <div className="modal fade help-model" id="cameraModal" tabIndex="-1" aria-labelledby="cameraModal" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">

              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>

              <div className="modal-body camera-model-body">
                <div className='cmera-header-flex'>
                  <img src={CameraIcon} alt="" className='camera-icon' />
                  <div>
                    <h3>Upload profile picture</h3>
                    <p>Please provide a clear image of your face against a plain background for the selection process.</p>
                  </div>
                </div>
                <div>
                  <img src={uploadProfile} alt="" className='uploadProfile' />
                </div>

              </div>

              <div className="modal-footer">
                <Button type="button" className='cancelModel' data-bs-dismiss="modal" >Cancel</Button>
                <Button type="button" onClick={() => setShowWebcam(true)} data-bs-toggle="modal" data-bs-target="#cameraModalTwo"> <img src={uploadIcon} alt="" className='uploadIcon' /> Upload</Button>
              </div>

            </div>
          </div>
        </div>

        {/* cameraModal Two */}


        <div className="modal fade help-model" id="cameraModalTwo" tabIndex="-1" aria-labelledby="cameraModalTwo" aria-hidden="true">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">

              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>

              <div className="modal-body camera-model-body">
                <div className='cmera-header-flex'>
                  <img src={CameraIcon} alt="" className='camera-icon' />
                  <div>
                    <h3>Upload profile picture</h3>
                    <p>Please provide a clear image of your face against a plain background for the selection process.</p>
                  </div>
                </div>
                <div className='uploade-fifty50'>
                  <div>
                    {showWebcam && (
                      <div>
                        <Webcam
                          audio={false}
                          className="uploadProfile"
                          screenshotFormat="image/jpeg"
                          videoConstraints={videoConstraints}
                          ref={webcamRef}
                        />
                        {timer > 0 && ShowTimer && (
                          <div className="timerContainer">
                            <div className="timerText">{timer}</div>
                          </div>
                        )}

                      </div>
                    )}
                  </div>
                  <div>

                    {imageSrc ? (
                      <img src={imageSrc} alt="Snapshot" className='uploadProfile' />
                    ) : (
                      <>
                        <img src={uploadProfile} alt="Snapshot" className='uploadProfile' />
                      </>
                    )}
                  </div>
                </div>

              </div>

              <div className="modal-footer">
                <button type="button" className='cancelModel' onClick={retakePicture}>
                  {imageSrc ? 'Retake Picture' : 'Take Picture'}
                </button>

                <button type="button" onClick={savePicture} data-bs-dismiss="modal">Save</button>
              </div>

            </div>
          </div>
        </div>
        {/* Start Test Model */}

        <div className="modal fade help-model" id="startTestModel" tabIndex="-1" aria-labelledby="startTestModel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">

              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>

              <div className="modal-body camera-model-body">
                <div className='cmera-header-flex'>
                  <img src={statTestIcon} alt="" className='camera-icon' />
                  <div>
                    <h3>Start Test</h3>
                    <p>Please check the below prerequisites. </p>
                  </div>
                </div>
                <Box className="starttest-main">
                  {screenSetting && screenSetting.active && (
                    <React.Fragment>
                      <FormControl className='privacy-policy-check'>
                        <Checkbox disabled style={checkboxStartTest} defaultChecked />
                        <p className='check-text'>Start Screen Share</p>
                      </FormControl>
                    </React.Fragment>
                  )}
                  {snapshotSetting && snapshotSetting.active && (
                    <React.Fragment>
                      <FormControl className='privacy-policy-check'>
                        <Checkbox disabled style={checkboxStartTest} defaultChecked />
                        <p className='check-text'>Why Camera & Profile Picture</p>
                      </FormControl>
                    </React.Fragment>
                  )}
                  {snapshotSetting && snapshotSetting.active && (
                    <React.Fragment>
                      <FormControl className='privacy-policy-check'>
                        <Checkbox disabled style={checkboxStartTest} defaultChecked />
                        <p className='check-text'>Multi Screen Check</p>
                      </FormControl>
                    </React.Fragment>
                  )}

                  <FormControl className='privacy-policy-check'>
                    <Checkbox
                      style={checkboxStartTest}
                      onChange={handleTermsChange}
                      checked={termsChecked}
                      disabled
                    />
                    <p className='check-text'>I Agree Terms & Conditions</p>
                  </FormControl>
                </Box>

              </div>

              <div className="modal-footer">
                <Button type="button" className='cancelModel' data-bs-dismiss="modal" >Cancel</Button>
                <Button type="button" disabled={!termsChecked} onClick={handleOverview} data-bs-dismiss="modal">Start</Button>
              </div>

            </div>
          </div>
        </div>

        <Modal isOpen={showModal} toggle={toggleModal} className="alert-model">
          <div className="modal-content">
            <button type="button" className="btn-close" aria-label="Close" onClick={toggleModal}></button>
            <ModalBody>
              <div className='alert-trangle-text'>
                {screenSetting && screenSetting.active && !isSharing ? (
                  <React.Fragment>
                    <img src={AlertTriangle} alt="" />
                    Please Share your Screen to start the test
                  </React.Fragment>
                ) : (
                  snapshotSetting && snapshotSetting.active && !cameraStarted ? (
                    <React.Fragment>
                      <img src={AlertTriangle} alt="" />
                      Turn on your camera to start the test
                    </React.Fragment>
                  ) : (
                    snapshotSetting && snapshotSetting.active && !cameraStarted && !imageSrc ? (
                      <React.Fragment>
                        <img src={AlertTriangle} alt="" />
                        Please upload your photo
                      </React.Fragment>
                    ) : null
                  )
                )}
              </div>
            </ModalBody>
            <ModalFooter className="review-footer">
              <Button color="secondary" onClick={toggleModal} className='cancelModel'>close</Button>
            </ModalFooter>
          </div>
        </Modal>


      </div>

    </>


  )
}

export default StartTest