import { SET_TEST_ID, SET_TEST_NAME, SET_VALUE, SET_QUESTION_INDEX,SET_CANDIDATE_SETTING ,SET_TEST_DATE, SET_TEST_TIME, SET_REMAINING_TIME, SET_TEST_SETTINGS, SET_COMPANY, SET_TOTAL_SCORE,
  SET_TOTAL_QUESTIONS, SET_ANSWER, RESET_ANSWER, SET_TEST_DATA, SET_FULL_SCREEN,LOGOUT } from "./actionTypes";

const initialState = {
  testId: null,
  name: null,
  value: null,
  index: null,
  candidate:null,
  date: null,
  time: null,
  newTime: null,
  screen: null,
  setting: null,
  data:null,
  company: null,
  score: null,
  questions: null,
  selectedAnswers: [],
  logout: null
};

const testReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TEST_ID:
      return {
        ...state,
        testId: action.payload,
      };
    case SET_TEST_NAME:
      return {
        ...state,
        name: action.payload,
      };
    case SET_VALUE:
      return {
        ...state,
        value: action.payload,
      };
    case SET_QUESTION_INDEX:
      return {
        ...state,
        index: action.payload,
      };
    case SET_CANDIDATE_SETTING:
      return {
        ...state,
        candidate: action.payload,
      };
    case SET_TEST_DATE:
      return {
        ...state,
        date: action.payload,
      };
   
    case SET_TEST_TIME:
      return {
        ...state,
        time: action.payload,
      };
    case SET_REMAINING_TIME:
      return {
        ...state,
        newTime: action.payload,
      };
    case SET_FULL_SCREEN:
      return {
        ...state,
        screen: action.payload,
      };
    case SET_TEST_SETTINGS:
      return {
        ...state,
        setting: action.payload,
      };
    case SET_COMPANY:
      return {
        ...state,
        company: action.payload,
      };
    case SET_TOTAL_SCORE:
      return {
        ...state,
        score: action.payload,
      };
    case SET_TEST_DATA:
      return {
        ...state,
        data: action.payload,
      };
    case SET_TOTAL_QUESTIONS:
      return {
        ...state,
        questions: action.payload,
      };
      case SET_ANSWER:
        const { testDataIndex, questionIndex, answerIndex, isReviewed, timeSpent } = action.payload;
        const updatedSelectedAnswers = state.selectedAnswers ? state.selectedAnswers.slice() : [];
        if (!updatedSelectedAnswers[testDataIndex]) {
          updatedSelectedAnswers[testDataIndex] = [];
        }
        updatedSelectedAnswers[testDataIndex][questionIndex] = { answerIndex, isReviewed, timeSpent };
        return { ...state, selectedAnswers: updatedSelectedAnswers };
        case RESET_ANSWER:
          const { testDataIndex: resetIndex, questionIndex: resetQIndex } = action.payload;
          const resetSelectedAnswers = state.selectedAnswers ? state.selectedAnswers.slice() : [];
          if (!resetSelectedAnswers[resetIndex]) {
            resetSelectedAnswers[resetIndex] = [];
          }
          resetSelectedAnswers[resetIndex][resetQIndex] = null;
          return { ...state, selectedAnswers: resetSelectedAnswers };
          case LOGOUT:
            return {
              ...initialState,
              logout: null,
            };
          
    default:
      return state;
  }
};

export default testReducer;
