

export const SET_TEST_ID='SET_TEST_ID';

export const SET_TEST_NAME='SET_TEST_NAME';

export const SET_CANDIDATE_SETTING='SET_CANDIDATE_SETTING';

export const SET_TEST_DATE='SET_TEST_DATE';

export const SET_VALUE='SET_VALUE';

export const SET_QUESTION_INDEX='SET_QUESTION_INDEX';

export const SET_TEST_TIME='SET_TEST_TIME';

export const SET_REMAINING_TIME='SET_REMAINING_TIME';

export const SET_FULL_SCREEN='SET_FULL_SCREEN';

export const SET_TEST_SETTINGS='SET_TEST_SETTINGS';

export const SET_COMPANY='SET_COMPANY';

export const SET_TOTAL_SCORE='SET_TOTAL_SCORE';

export const SET_TOTAL_QUESTIONS='SET_TOTAL_QUESTIONS';

export const SET_ANSWER='SET_ANSWER';

export const RESET_ANSWER='RESET_ANSWER'; 

export const SET_TEST_DATA='SET_TEST_DATA'; 

export const LOGOUT='LOGOUT';
