

export const SET_INVITE_ID='SET_INVITE_ID';

export const SET_CANDIDATE_NAME='SET_CANDIDATE_NAME';

export const SET_CANDIDATE_EMAIL='SET_CANDIDATE_EMAIL';

export const SET_CANDIDATE_MODE='SET_CANDIDATE_MODE';

export const SET_CANDIDATE_IMAGE='SET_CANDIDATE_IMAGE';

export const SET_CANDIDATE_IMAGE_STATUS='SET_CANDIDATE_IMAGE_STATUS';

export const SET_EXTRA_FIELDS='SET_EXTRA_FIELDS';

export const SET_CANDIDATE_EXIT='SET_CANDIDATE_EXIT';

export const SET_CANDIDATE_TAB_SWITCH='SET_CANDIDATE_TAB_SWITCH';

export const SET_CANDIDATE_TOTAL_TIME='SET_CANDIDATE_TOTAL_TIME';

export const SET_CANDIDATE_SHUFFLE='SET_CANDIDATE_SHUFFLE';

export const SET_CANDIDATE_LOGOUT='SET_CANDIDATE_LOGOUT';

