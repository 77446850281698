import React, { useState, useEffect} from 'react'


import {SubmitIcon, AlertTriangle, WhoWeAreImgpowerd , fillDetails ,logo} from '../../Common/CommonImages';
import { useNavigate } from 'react-router-dom';
import { FormControl, Box, TextField, Button, Input, InputLabel } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import PhoneInput from 'react-phone-number-input';
import { useDispatch, useSelector } from 'react-redux';
import 'react-phone-number-input/style.css';
import { BASE_URL, handleDynamicRequest } from '../../Common/CommonExport';
import {Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { setCandidateEmail, setCandidateName,setExtraFieldArray } from '../../store/Candidate/action';
import { setFullScreen, setRemainingTime } from '../../store/Test/action';
const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

function EnterDetails() {

  const [value, setValue] = useState();
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => setShowModal(!showModal);

  const [selectedFile, setSelectedFile] = useState(null);
  const testId = useSelector((state) => state.testReducer.testId);
  const testTime = useSelector((state) => state.testReducer.time);
  const candidateSettings = useSelector((state) => state.testReducer.candidate);
  const [alertMessage, setAlertMessage] = useState({ success: '', error: '' });
  const [final, setFinal] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handlePage = () => {
    navigate('/start-test')
  }
  const handleEnterDetails = () => {
    navigate(-1)
  }
  useEffect(() => {

  }, [final]);
  useEffect(()=>{
    dispatch(setFullScreen(false));
    const convertToSeconds = (timeString) => {
      const [hours, minutes, seconds] = timeString.split(':').map(Number);
      return hours * 3600 + minutes * 60 + seconds;
    };

    const totalSeconds = convertToSeconds(testTime);
    dispatch(setRemainingTime(totalSeconds));
  });
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    college: '',
    mobile: ''
  });

  const [formErrors, setFormErrors] = useState({
    name: false,
    email: false,
    college: false,
    mobile: false
  });

  const handleFormChangeEmail = (event) => {
    const { name, value } = event.target;
  const emailRegex = /\S+@\S+\.\S+/;
    const customValidationRegex = /@gmail\.com|yourcustommessage/;
    const isValidEmail = emailRegex.test(value);
    const isValidCustomMessage = customValidationRegex.test(value);

    setFormErrors({
      ...formErrors,
      email: !(isValidEmail || isValidCustomMessage),
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setFormErrors({ ...formErrors, [name]: value.trim() === '' });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const isFormValid = Object.values(formErrors).every((error) => !error);
    
    if (isFormValid) {
      handleCandidate();
    }
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleClearFile = () => {
    setSelectedFile(null);
  };

  // const handleCandidate = () => {
  //   const requestBody = {
  //     candidateEmail:formData.email, candidateName:formData.name, extraFieldArray: [
        
  //     ]
  //   };
  //   const method = 'POST';
  //   const url = `${BASE_URL}/api/exam/test/${testId}/checkCanGiveExam`;
  //   const wrongResponse = (data) => {
  //     console.log("Wrong response received:", data);
  //     if (data && data.error) {
  //       setFinal(data.error);
  //       console.log("Final state updated with error:", data.error);
  //       setAlertMessage({ success: '', error: data.error });
  //       setShowModal(true);
  //     }
  
  //   };
  //   const rightResponse = (data) => {
  //     if (data) {
       
  //       dispatch(setCandidateEmail(formData.email));
  //       dispatch(setCandidateName(formData.name));
  //       dispatch(setExtraFieldArray());
  //       handlePage(); 
  //     }
  //   };
  //   handleDynamicRequest(method, url, requestBody, wrongResponse, rightResponse);
  // };

  const handleCandidate = () => {
    const requestBody = {
      candidateEmail: formData.email,
      candidateName: formData.name,
      extraFieldArray: []
    };
    const method = 'POST';
    const url = `${BASE_URL}/api/exam/test/${testId}/checkCanGiveExam`;
    
    const wrongResponse = (data) => {
   
      if (data && data.error) {
        setFinal(data.error);
        
        setAlertMessage({ success: '', error: data.error });
        setShowModal(true);
      } else {
        console.log("Unexpected data received:", data);
      }
    };
    
    const rightResponse = (data) => {
      if (data) {
        dispatch(setCandidateEmail(formData.email));
        dispatch(setCandidateName(formData.name));
        dispatch(setExtraFieldArray());
        handlePage(); 
      }
    };
  
    handleDynamicRequest(method, url, requestBody, wrongResponse, rightResponse);
  };



  return (

    <>
      <div className="App">

        <div className="main-head">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <header className="logo-header">
                  <a href='/' ><img src={logo} alt="logo" className='hire-logo' /></a>
                </header>
              </div>
            </div>
          </div>
        </div>

        <div className="progress-steps">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">

                <div className="progress-steps-inner">
                  <a href='/'><img src={logo} alt="logo" className='desktop-logo' /></a>
                  <ul className="timeline">
                    <li className="active completed-check"><span>Summary</span></li>
                    <li className="active line-not-active"><span>Enter Details</span></li>
                    <li><span>Start Test</span></li>
                  </ul>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div className="middle-steps-content">
          <div className="max-container-middle-text">
            <div className="row">
              <div className="col-lg-12">
                <div className='middle-steps-inner-content'>
                  <div className='row middle-info-item'>
                    <div className='col-lg-12'>

                      <div className='practice-test-left'>
                        <div className='practice-test-name'>
                          <img src={fillDetails} alt="Test Sheet Icon" className='fill-details-ring' />
                          <h1>Fill Details</h1>
                        </div>
                      </div>

                    </div>

                  </div>

                  <div className="row">
                    <div className="col-lg-12">
                      <div className='form-fill-details'>
                        <Box className='formbox' component="form" autoComplete="off" onSubmit={handleFormSubmit}>
                        {candidateSettings.map((field, index) => {
        if (field === 'Name') {
          return (
            <FormControl key={index} className='formfieldbox formname'>
              <TextField
                name="name"
                label="Name"
                variant="outlined"
                color="success"
                required
                onChange={handleFormChange}
                error={formErrors.name}
              />
            </FormControl>
          );
        } else if (field === 'Email') {
          return (
            <FormControl key={index} className="formfieldbox formmail">
              <TextField
                name="email"
                label="Email"
                variant="outlined"
                color="success"
                required
                onChange={handleFormChangeEmail}
                error={formErrors.email}
                helperText={formErrors.email && 'Invalid email address'}
              />
            </FormControl>
          );
        }else if(field === 'College') {
          return(
            <FormControl className="formfieldbox formcollege">
            <TextField
              name="college"
              label="College"
              variant="outlined"
              color="success"
              required
              onChange={handleFormChange}
              error={formErrors.college}
            />
          </FormControl>
          );
        
        }else if(field === 'Mobile') {
          return(
            <FormControl className="formfieldbox formcall">
            <PhoneInput
            className='phoneNumber'
            placeholder="Mobile No"
            name="mobile"
            variant="outlined"
            color="success"
            value={value}
            onChange={setValue}
            />
        </FormControl>
          );
        }else if(field === 'Other'){
          return(  <div className="col-lg-12 mt-3 mb-3">
          <div className="className='bottom-next-border'">
            <div className='upload-resume-label'>Upload Resume *</div>
            {!selectedFile ? (
              <label htmlFor="file-upload">
                <Button
                  component="span"
                  variant="contained"
                  className='upload-btn'
                  startIcon={<CloudUploadIcon />}
                >
                  Upload
                </Button>
                <input
                  type="file"
                  id="file-upload"
                  style={{ display: 'none' }}
                  onChange={handleFileChange}
                />
              </label>
            ) : (
              <div>
                <Button
                  component="span"
                  variant="contained"
                  className='uploaded-text'
                >{selectedFile.name}
                  <IconButton onClick={handleClearFile} className='close-icon-upload'>
                    <ClearIcon />
                  </IconButton>

                </Button>
                <span></span>

              </div>
            )}
          </div>

        </div>);
        }
      })}

               

                          

                        

                          <div className="col-lg-12">
                            <div className='bottom-next-border abs-form'>
                              <Button onClick={handleEnterDetails} className='back'>Back</Button>
                              <Button type="submit"   disabled={Object.values(formErrors).some((error) => error)} >Next</Button>
                            </div>
                          </div>
                        </Box>

                      </div>
                    </div>
                  </div>

                </div>

              </div>

              <div className="col-lg-12">
                <div className='footer-logo abs-form-footer'>
                  <img src={WhoWeAreImgpowerd} alt="" className='who-wer-are-p-img' />
                </div>
              </div>

            </div>

          </div>

        </div>

      </div>

      <Modal isOpen={showModal} toggle={toggleModal} className="alert-model">
       
          <div className="modal-content">
            <button type="button" className="btn-close" aria-label="Close" onClick={toggleModal}></button>
          
            <ModalBody>
              <div className='alert-trangle-text'><img src={AlertTriangle} alt="" />{final}</div>
            </ModalBody>
            <ModalFooter className="review-footer">
              <Button color="secondary" onClick={toggleModal} className='cancelModel'>close</Button>
             
            </ModalFooter>
          </div>
       
      </Modal>
    </>


  )
}

export default EnterDetails