import { BASE_URL, handleDynamicRequest, useDispatch, useNavigate } from '../../Common/CommonExport';
import { logo, testSheet, clockIcon, QuestionHelp, Cognizant, PracticeTest, CheckBox, CodeBox, HelpIcon, WhoWeAreImgpowerd } from '../../Common/CommonImages';

import React, { useState, useEffect } from 'react'
import { Button, Tooltip, Zoom } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import {
  setTestId, setTestName, setCandiateSettings, setTestTime, setTestDate, setTestSettings,
  setCompany, setTotalScore, setTotalQuestions, setLogout
} from '../../store/Test/action';
import moment from "moment";
import { toast, ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
function Summary() {
  const navigate = useNavigate();
  const [testSummary, setTestSummary] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const [test, setTest] = useState([]);
  const [alertMessage, setAlertMessage] = useState({ success: '', error: '' });
  const dispatch = useDispatch();

  // const testID = '660e2c046898012344a3cc71';

  const link = window.location.href;

  const parts = link.split("-");
  const uniqueId = parts[parts.length - 1];

  const handleSummary = (uniqueId) => {
    const requestBody = {};
    const method = 'GET';
    const url = `${BASE_URL}/api/exam/test/${uniqueId}`;
    const wrongResponse = () => {
      setAlertMessage({ success: '', error: 'Wrong Credentials' });
    };
    const rightResponse = (data) => {
      if (data) {
        setTestSummary(data.data);
        const startDate = moment(data.data?.startDate).format("DD MMM");
        const endDate = data.data?.endDate ? moment(data.data?.endDate).format(" DD MMM") : " NA";
        setStartDate(data.data?.startDate);
        setEndDate(data.data?.endDate);
        dispatch(setTestId(data.data._id));
        dispatch(setTestSettings(data.data.proctoringSettings));
        dispatch(setCompany(data.data.createdBy?.client?.companyName));
        dispatch(setTotalScore(data.data.totalScore));
        dispatch(setCandiateSettings(data.data.candiateSettings));
        dispatch(setTestName(data.data.name.split(' ').map(word =>
          word.charAt(0).toUpperCase() + word.slice(1)).join(' ')));
        dispatch(setTestDate({ startDate, endDate }));

      }
    };
    handleDynamicRequest(method, url, requestBody, wrongResponse, rightResponse);
  };

  const handleTest = (uniqueId) => {
    const requestBody = {};
    const method = 'GET';
    const url = `${BASE_URL}/api/exam/test/${uniqueId}/summary`;
    const wrongResponse = () => {
      setAlertMessage({ success: '', error: 'Wrong Credentials' });
    };
    const rightResponse = (data) => {
      if (data) {
        setTest(data.data);
        dispatch(setTotalQuestions(data.data.totalQuestions));
      }
    };
    handleDynamicRequest(method, url, requestBody, wrongResponse, rightResponse);
  };

  useEffect(() => {
    dispatch(setLogout());
    handleSummary(uniqueId);
    handleTest(uniqueId);
  }, []);

  // const handlePage = () => {
  //   if(startDate){
  //     navigate('/enter-details')
  //   }

  // }

  const handlePage = () => {
    const currentDate = new Date();
    const startDateObject = new Date(startDate);
    if (startDateObject < currentDate) {
      if (endDate) {
        const endDateObject = new Date(endDate);
        if (endDateObject > currentDate) {
          navigate('/enter-details');
        } else {
          toast.error('Test End Date is over');
        }
      } else {
        navigate('/enter-details'); 
      }
    } else {
      toast.error('Test Start Date is About to start');
    }
  };

  const [open, setOpen] = React.useState(false);
  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

 

  return (
    <>
      {testSummary && (
        <div className="App">
          <ToastContainer />
          <div className="main-head">
            <div className="container-fluid">

              <div className="row">
                <div className="col-lg-12">
                  <header className="logo-header">
                    <a href='#'><img src={logo} alt="logo" className='hire-logo' /></a>
                  </header>
                </div>
              </div>
            </div>
          </div>
          <div className="progress-steps">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="progress-steps-inner">
                    <a href='#'><img src={logo} alt="logo" className='desktop-logo' /></a>
                    <ul className="timeline">
                      <li className="active line-not-active"><span>Summary</span></li>
                      <li><span>Enter Details</span></li>
                      <li><span>Start Test</span></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="middle-steps-content">
            <div className="max-container-middle-text">
              <div className="row">
                <div className="col-lg-12">
                  <div className='middle-steps-inner-content'>
                    <div className='row middle-info-item'>
                      <div className='col-lg-9'>
                        <div className='practice-test-left'>
                          <div className='practice-test-name'>
                            <img src={testSheet} alt="Test Sheet Icon" className='test-sheet-img' />
                            <h1>{testSummary?.name &&
                              testSummary?.name.split(' ').map(word =>
                                word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
                            }</h1>
                          </div>
                          <span className='cognizant'><img src={Cognizant} alt="" /> {testSummary?.createdBy?.client?.companyName}</span>
                          <ClickAwayListener onClickAway={handleTooltipClose}>
                            <div className='tooltip-with-para'>
                              <Tooltip
                                PopperProps={{
                                  disablePortal: true,
                                }}
                                className="tooltip-practice"
                                onClose={handleTooltipClose}
                                open={open}
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                arrow
                                title="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
                                placement="top"
                                TransitionComponent={Zoom}
                              >
                                <Button onClick={handleTooltipOpen}><img src={PracticeTest} alt="" /></Button>
                              </Tooltip>
                              <p>Try hire360’s Practice test</p>
                            </div>
                          </ClickAwayListener>
                        </div>
                      </div>

                      <div className='col-lg-3'>
                        <div className='practice-test-right'>
                          <span className='clock-icon-text'>
                            <img src={clockIcon} alt="" />
                            {
                              (() => {
                                const totalTimingString = testSummary?.totalTiming; // Assuming testSummary?.totalTiming is "180"
                                if (totalTimingString) {
                                  const totalTiming = parseInt(totalTimingString);
                                  const formattedTime = moment
                                    .utc(
                                      moment
                                        .duration(totalTiming, "minutes")
                                        .asMilliseconds()
                                    )
                                    .format("HH:mm:ss");
                                  dispatch(setTestTime(formattedTime));
                                  return formattedTime;
                                }
                                return '';
                              })()
                            }
                          </span>
                          <Button data-bs-toggle="modal" data-bs-target="#helpModal"><img src={QuestionHelp} alt="" /> Help</Button>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-12">
                        <div className='test-description'>
                          <h2>Test Description</h2>
                          <p>{testSummary?.description &&
                            testSummary?.description.charAt(0).toUpperCase() + testSummary?.description.slice(1)
                          }</p>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-12">
                        <div className='test-description'>
                          <h2>Total Questions <span>({test?.totalQuestions})</span></h2>
                          {/* <div className='programing-text-name'>
                            <span><img src={CheckBox} alt="" />15 MCQ</span>
                            <span><img src={CodeBox} alt="" />15 Programming</span>
                            <span><img src={CodeBox} alt="" />15 SQL</span>
                          </div> */}

                          <div className='programing-text-name'>
                            {test?.questionTypes && test.questionTypes.map((questionType, index) => {
                              if (questionType?.type === "MCQ") {
                                return <span key={index}><img src={CheckBox} alt="" />{questionType?.count} MCQ</span>;
                              } else if (questionType?.type === "Programming") {
                                return <span key={index}><img src={CodeBox} alt="" />{questionType?.count} Programming</span>;
                              } else if (questionType?.type === "SQL") {
                                return <span key={index}><img src={CodeBox} alt="" />{questionType?.count} SQL</span>;
                              } else {
                                return null;
                              }
                            })}
                          </div>

                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-12">
                        <div className='test-description'>
                          <h2>General Instruction</h2>
                          <ul>
                            <li> Test duration:{" "}
                              {moment
                                .utc(
                                  moment
                                    .duration(testSummary?.totalTiming, "minutes")
                                    .asMilliseconds()
                                )
                                .format("HH:mm")}{" "}
                              hrs</li>
                            <li>Mention the expected duration of the test.</li>
                            <li>Clearly state the objective of the coding test.</li>
                            <li>Specify the problem or task that the candidate is expected to solve.</li>
                            <li>Indicate the programming language(s) and any specific tools or frameworks that should be used for the test.</li>
                            <li>If applicable, provide instructions for setting up the development environment.</li>
                            <li>Specify any dependencies or libraries that need to be installed.</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className='bottom-next-border'>
                    <Button onClick={handlePage}>Next</Button>
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className='footer-logo'>
                    <img src={WhoWeAreImgpowerd} alt="" className='who-wer-are-p-img' />
                  </div>
                </div>

              </div>
            </div>
          </div>

          {/* Help Model */}

          <div className="modal fade help-model" id="helpModal" tabIndex="-1" aria-labelledby="helpModal" aria-hidden="true">
            <div className="modal-dialog">
              <div className="modal-content">

                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>

                <div className="modal-body">
                  <img src={HelpIcon} alt="" className='camera-icon' />
                  <div>
                    <h3>Help & Support</h3>
                    <p>Please contact the test admin at following email.</p>
                  </div>
                </div>

                <div className="modal-footer">
                  <Button className='okaybtn' type="button" data-bs-dismiss="modal" >Okay</Button>
                </div>

              </div>
            </div>
          </div>

        </div>
      )}
    </>
  )
}

export default Summary