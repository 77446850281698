// import React from 'react';

import React, { useState, useEffect, useRef } from 'react';
import { ToastContainer } from 'react-toastify';
import {
  clockIcon, testSheet, WhoWeAreImgpowerd, CognizantColor, testScore, userTestImage, questionOverview,
  questionMark, questionMarkStar, resetAns, maxScore, mobileViewQs, questionImgList, closeIconQuestion
} from '../../Common/CommonImages'

import LinearProgress from '@mui/joy/LinearProgress';
import {
  Button, Checkbox, FormControl, FormLabel, FormControlLabel, RadioGroup, Radio,
  Tab, Box, Accordion, AccordionSummary, AccordionDetails, Typography, ButtonGroup
} from '@mui/material';
import { BASE_URL, handleDynamicRequest, handleDynamicRequestHeader, handleDynamicRequestImage } from '../../Common/CommonExport';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Webcam from "react-webcam";
import { setRemainingTime, setAnswer, resetAnswer, setFullScreen, setTestData, setValue, setQuestionIndex } from '../../store/Test/action';
import { setInviteId, setCandidateFullExit, setCandidateTabSwitch, setCandidateTotalTime, setCandidateShuffle, setCandidateImageStatus } from '../../store/Candidate/action';
import axios from "axios";
const base64 = require("base64-js");

const videoConstraints = {
  width: 1280,
  height: 720,
  facingMode: "user"
};

function Overview() {
  const testName = useSelector((state) => state.testReducer.name);
  const remainingTime = useSelector((state) => state.testReducer.newTime);
  const testId = useSelector((state) => state.testReducer.testId);
  const testTime = useSelector((state) => state.testReducer.time);
  const company = useSelector((state) => state.testReducer.company);
  const totalScore = useSelector((state) => state.testReducer.score);
  const totalQue = useSelector((state) => state.testReducer.questions);
  const value = useSelector((state) => state.testReducer.value);
  const questionIndex = useSelector((state) => state.testReducer.index);
  const screen = useSelector((state) => state.testReducer.screen);
  const selectedAnswers = useSelector((state) => state.testReducer.selectedAnswers);
  const candidateName = useSelector((state) => state.candidateReducer.name);
  const candidateEmail = useSelector((state) => state.candidateReducer.email);
  const candidateFields = useSelector((state) => state.candidateReducer.fields);
  const inviteId = useSelector((state) => state.candidateReducer.id);
  const candidateImage = useSelector((state) => state.candidateReducer.image);
  const candidateImageStatus = useSelector((state) => state.candidateReducer.status);
  const candidateShuffle = useSelector((state) => state.candidateReducer.shuffle);
  const candidate_full_screen_exit_count = useSelector((state) => state.candidateReducer.count);
  const candidate_tab_switch_count = useSelector((state) => state.candidateReducer.tab);
  const candidate_total_time_spent = useSelector((state) => state.candidateReducer.time);
  const setting = useSelector((state) => state.testReducer.setting);
  const testData = useSelector((state) => state.testReducer.data);
  const [alertMessage, setAlertMessage] = useState({ success: '', error: '' });
  const [showQuestions, setShowQuestions] = useState(true);
  const [testSummary, setTestSummary] = useState([]);
  const [testQuestions, setTestQuestions] = useState([]);
  const [testTotalTime, setTestTotalTime] = useState(0);
  const [SingleQuestionTime, setSingleQuestionTime] = useState(0);
  const [tabSwitchCount, setTabSwitchCount] = useState(0);
  const [testAllQuestions, setTestAllQuestions] = useState([]);
  const [answerId, setAnswerId] = useState('');
  const questionsRef = useRef([]);
  const [showWebcam, setShowWebcam] = useState(false);
  const [cameraStarted, setCameraStarted] = useState(false);
  const [stream, setStream] = useState(null);
  const videoRef = useRef(null);
  const [intervalId, setIntervalId] = useState(null);
  const [isSharing, setIsSharing] = useState(false);
  const [mediaStream, setMediaStream] = useState(null);
  // const [value, setValue] = React.useState('1');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleReview = () => {
    dispatch(setCandidateFullExit(screen ? 1 : 0));
    dispatch(setCandidateTabSwitch(tabSwitchCount));
    dispatch(setCandidateTotalTime(testTotalTime));
    navigate('/review')
  }

  const [timeSpent, setTimeSpent] = useState(0);

  const timerRef = useRef(null);

  useEffect(() => {

    const timer = setInterval(() => {
      setTimeSpent((prevTimeSpent) => prevTimeSpent + 1);
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  // useEffect(() => {
  //   const handleVisibilityChange = () => {
  //     if (!document.hidden) {
  //       setTabSwitchCount((prevCount) => prevCount + 1);
  //     }
  //   };
  //   document.addEventListener("visibilitychange", handleVisibilityChange);
  //   return () => {
  //     document.removeEventListener("visibilitychange", handleVisibilityChange);
  //   };
  // }, []);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (!document.hidden) {

        setTabSwitchCount((prevCount) => prevCount + 1);

        dispatch(setCandidateTabSwitch((prevCount) => prevCount + 1))

      }
    };

    ;
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      console.log('Removing event listener');
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  
  useEffect(() => {
    const snapshotSetting = setting.find(setting => setting.setting === 'Take Snapshots via webcam every 30 second');
    if (snapshotSetting && snapshotSetting.active) {
      startCamera();
    //  startScreenShare();
    }
    const snapshotSettingFull = setting.find(setting => setting.setting === 'Turn on fullscreen while test');
    if (snapshotSettingFull && snapshotSettingFull.active) {
      dispatch(setFullScreen(true));
    } else {
      dispatch(setFullScreen(false));
    }
    const shuffleSetting = setting.find(setting => setting.setting === 'Shuffle Questions for each candidate');
    if (shuffleSetting && shuffleSetting.active) {
      dispatch(setCandidateShuffle(true));
    }

    const tabSwitchSetting = setting.find(setting => setting.setting === 'Logout on leaving a test interface');
    if (tabSwitchSetting && tabSwitchSetting.active) {
      if (tabSwitchCount > 0) {
        handleSubmit();
      }
    }
    setInterval(() => {
      if(snapshotSetting && snapshotSetting.active){
        handleCameraSnapshot();
        
      } 
   
    }, 30000);
    handleProfileSnapshot();
    return () => {
      clearInterval(intervalId);
    };
  
  }, [setting, tabSwitchCount, inviteId]);



  const startCamera = () => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia({ video: true })
        .then(stream => {
          setStream(stream);
          setCameraStarted(true);
        })
        .catch(error => {
          console.error('Error starting camera:', error);
        });
    } else {
      console.error('getUserMedia not supported');
    }
  };
  const startScreenShare = async () => {
    try {
      const stream = await navigator.mediaDevices.getDisplayMedia({ video: true });
      setMediaStream(stream);
      setIsSharing(true);
    } catch (error) {
      console.error('Error sharing screen:', error);
    }
  };
  useEffect(() => {
    let intervalId;
    const convertToSeconds = (timeString) => {
      const [hours, minutes, seconds] = timeString.split(':').map(Number);
      return hours * 3600 + minutes * 60 + seconds;
    };

    const totalSeconds = convertToSeconds(testTime);

    if (remainingTime > 0) {
      intervalId = setInterval(() => {
        setTestTotalTime(prevState => prevState + 1);
        dispatch(setRemainingTime(remainingTime - 1));
      }, 1000);
    } else if (remainingTime === undefined) {

      dispatch(setRemainingTime(totalSeconds));
    }

    // if (remainingTime === 0) {
    //   handleSubmit();
    // }

    return () => {
      clearInterval(intervalId);
    };
  }, [remainingTime, testTime]);

  const formatTime = (seconds) => {
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${hrs < 10 ? '0' + hrs : hrs}:${mins < 10 ? '0' + mins : mins}:${secs < 10 ? '0' + secs : secs}`;
  };

  useEffect(() => {
    const isMobile = window.matchMedia("(max-width: 768px)").matches;
    if (isMobile) {
      setShowQuestions(false);
    }
    handleSummary(testId);
    handleProfileSnapshot();
    if(value===null || value===undefined){
      dispatch(setValue("1"));
    }
    if(questionIndex!==null){
      handleButtonClick(questionIndex);
    }
  }, []);


  const handleChange = (event, newValue) => {
    setCurrentQuestionIndex(null);
    dispatch(setValue(newValue));
    dispatch(setQuestionIndex(newValue));
  };
  const handleChangeNew = (event, newValue, index) => {
    dispatch(setValue(newValue));
    setTimeout(() => {
      scrollToQuestion(index);
      setCurrentQuestionIndex(index);
      dispatch(setQuestionIndex(newValue));
    }, 200)

  };
  const handleSubmit = () => {
    const requestBody = {
      inviteId: inviteId,
      candidate_tab_switch_count: candidate_tab_switch_count,
      candidate_total_time_spent: candidate_total_time_spent,
      candidate_full_screen_exit_count: candidate_full_screen_exit_count,
      testEndTime: new Date()
    };
    const method = 'POST';
    const url = `${BASE_URL}/api/exam/test/${testId}/submit`;
    const wrongResponse = () => {
      setAlertMessage({ success: '', error: 'Wrong Credentials' });
    };
    const rightResponse = (data) => {
      if (data) {

        setTimeout(() => {

          navigate('/');
          window.location.reload();

        }, 5000);
      }
    };
    handleDynamicRequest(method, url, requestBody, wrongResponse, rightResponse);


  }

  const checkboxStyle = {
    width: '40px',
    height: '40px',
  };

  const handleMobile = () => {
    console.log(showQuestions)
    setShowQuestions(prevState => !prevState);
  }

  const handleSummary = (uniqueId) => {
    const questionsList = [];
    const randomSkills = [];
    const requestBody = {};
    const method = 'GET';
    const url = `${BASE_URL}/api/exam/test/${uniqueId}/Combinequestions`;
    const wrongResponse = () => {
      setAlertMessage({ success: '', error: 'Wrong Credentials' });
    };
    const rightResponse = (data) => {
      if (data) {
        const snapshotSettingFull = setting.find(setting => setting.setting === 'Turn on fullscreen while test');

        if (snapshotSettingFull && snapshotSettingFull.active) {
          dispatch(setFullScreen(true));
        }
        setTestSummary(data);

        if (candidateShuffle && testData.length === 0) {
          data.testSummaryData.skills.forEach(skill => {
            skill.easy_question = shuffle(skill.easy_question);
            skill.medium_question = shuffle(skill.medium_question);
            skill.hard_question = shuffle(skill.hard_question);
          });

          function shuffle(array) {
            for (let i = array.length - 1; i > 0; i--) {
              const j = Math.floor(Math.random() * (i + 1));
              [array[i], array[j]] = [array[j], array[i]];
            }
            return array;
          }

          dispatch(setTestData(data?.testSummaryData?.skills));
        } else {

          dispatch(setTestData(data?.testSummaryData?.skills));
        }

        data?.testSummaryData?.skills.forEach((skill) => {
          if (!skill.isRandom) {
            const tempQuestions = [
              ...skill.easy_question,
              ...skill.hard_question,
              ...skill.medium_question,
            ];
            questionsList.push(...tempQuestions);
          } else {
            const scores = {};
            skill.score[0].split(",").forEach((item) => {
              const [key, value] = item.split("(");
              scores[key.toLowerCase()] = parseInt(value);
            });
            randomSkills.push({
              skillId: skill.skill_id,
              scores: scores,
            });
          }
        });

        data?.random.forEach((skill) => {
          const tempQuestions = skill.question.map((q) => ({
            ...q,
            score: randomSkills.find((rSkill) => rSkill.skillId._id === skill.skillId._id)?.scores[q.difficultyLevelId.level] || 0,
            isRandom: true,
          }));
          questionsList.push(...tempQuestions);
        });

        data?.testData?.proctoringSettings.forEach((setting) => {
          if (setting.setting === "Shuffle Questions for each candidate" && setting.active) {
            for (let i = questionsList.length - 1; i > 0; i--) {
              const j = Math.floor(Math.random() * (i + 1));
              [questionsList[i], questionsList[j]] = [
                questionsList[j],
                questionsList[i],
              ];
            }
          }
        });

        setTestQuestions(questionsList);
        handleCandidate(uniqueId, questionsList); // Call handleCandidate with questionsList
        return questionsList;
      }
    };
    handleDynamicRequest(method, url, requestBody, wrongResponse, rightResponse);
  };


  const handleCandidate = async (uniqueId, questionsList) => {
    const requestBody = {
      candidateName: candidateName,
      candidateEmail: candidateEmail,
      candidateMobile: null,
      extraFieldArray: [],
      testStartTime: new Date(),
      inviteTime: new Date(),
      candidateQuestions: questionsList
    };
    const method = 'POST';
    const url = `${BASE_URL}/api/exam/test/${uniqueId}/candidateFields`;

    const wrongResponse = () => {
      setAlertMessage({ success: '', error: 'Wrong Credentials' });
    };
    const rightResponse = (data) => {
      if (data) {
        dispatch(setInviteId(data.data._id));
      }
    };
    handleDynamicRequestHeader(method, url, requestBody, null, wrongResponse, rightResponse);
  };


  const submitAnswer = async (uniqueId, questionId, isBookMarked, newValue) => {
    clearInterval(timerRef.current);
    dispatch(setCandidateFullExit(screen ? 1 : 0));
    dispatch(setCandidateTabSwitch(tabSwitchCount));
    dispatch(setCandidateTotalTime(testTotalTime));
    const requestBody = {
      candidate_answer: [uniqueId],
      candidate_full_screen_exit_count: screen ? 1 : 0,
      candidate_tab_switch: tabSwitchCount,
      candidate_total_time_spent: testTotalTime,
      inviteId: inviteId,
      isBookMarked: uniqueId ? true : false,
      questionId: questionId,
      question_static_object: questionId,
      time_spent: timeSpent,
    };

    const method = 'POST';
    const url = `${BASE_URL}/api/exam/test/${testId}/singleResponse`;
    setAnswerId('');
    setTimeSpent(0);
    const wrongResponse = () => {
      setAlertMessage({ success: '', error: 'Wrong Credentials' });

    };
    const rightResponse = (data) => {
      if (data) {
        handleAnswerSelection(newValue.value, newValue.questionIndex, newValue.selectedAnswer, newValue.status)
      }
    };
    handleDynamicRequestHeader(method, url, requestBody, null, wrongResponse, rightResponse);
  };



  const handleCameraSnapshot = async () => {
    try {
      console.log(inviteId)
      if (inviteId) {
        const snapshotData = videoRef.current.getScreenshot();
        const requestBody = { snapshotData };
        const method = 'GET';
        const url = `${BASE_URL}/api/exam/test/${testId}/S3Url?inviteId=${inviteId}`;
        console.log(snapshotData, 'snapshotData')
        const wrongResponse = () => {
          setAlertMessage({ success: '', error: 'Wrong Credentials' });
        };

        const rightResponse = async (data) => {
          if (data) {
            const base64Data = snapshotData.replace(
              /^data:image\/(png|jpeg|jpg);base64,/,
              ""
            );
            const imageBytes = base64.toByteArray(base64Data);

            await axios.request({
              method: "PUT",
              headers: {
                "Content-Type": "image/jpeg",
              },
              url: data.uploadURL,
              data: imageBytes,
            });

         }
        };

        await handleDynamicRequestHeader(method, url, requestBody, null, wrongResponse, rightResponse);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleProfileSnapshot = async () => {
    try {
      console.log(inviteId)
      if (inviteId && candidateImage && !candidateImageStatus) {
        const snapshotData = candidateImage;
        const requestBody = { snapshotData };
        const method = 'GET';
        const url = `${BASE_URL}/api/exam/test/${testId}/S3Url?inviteId=${inviteId}&snaphotType=profile`;
       
        const wrongResponse = () => {
          setAlertMessage({ success: '', error: 'Wrong Credentials' });
        };

        const rightResponse = async (data) => {
          if (data) {
            const base64Data = snapshotData.replace(
              /^data:image\/(png|jpeg|jpg);base64,/,
              ""
            );
            const imageBytes = base64.toByteArray(base64Data);

            await axios.request({
              method: "PUT",
              headers: {
                "Content-Type": "image/jpeg",
              },
              url: data.uploadURL,
              data: imageBytes,
            });
            dispatch(setCandidateImageStatus(true));
         }  
        };

        await handleDynamicRequestHeader(method, url, requestBody, null, wrongResponse, rightResponse);
      }


    } catch (error) {
      console.error("Error:", error);
    }
  };

  let totalQuestionsCount = 0;
  const totalQuestions = testData?.reduce((total, test) => {
    const easyLength = test?.easy_question?.length ?? 0;
    const mediumLength = test?.medium_question?.length ?? 0;
    const hardLength = test?.hard_question?.length ?? 0;
    return total + easyLength + mediumLength + hardLength;
  }, 0);


  const scrollToQuestion = (questionIndex) => {
    const questionRef = questionsRef.current[questionIndex];

    if (questionRef) {
      questionRef.scrollIntoView({ behavior: 'smooth' });

    } else {
      console.log('Question ref not found for index:', questionIndex);
    }
  };

  let overallQuestionIndex = 0;
  let overallQuestionIndexNew = 0;

  const handleAnswerSelection = (testDataIndex, questionIndex, answerIndex, isReviewed, timeSpent) => {
    dispatch(setAnswer(testDataIndex, questionIndex, answerIndex, isReviewed, timeSpent));
  };

  const handleAnswerReset = (testDataIndex, questionIndex) => {
    dispatch(resetAnswer(testDataIndex, questionIndex));
  };

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(null);

  const handleButtonClick = (questionIndex) => {
    console.log(questionIndex, 'questionIndex')
    scrollToQuestion(questionIndex);
    setCurrentQuestionIndex(questionIndex);
    dispatch(setQuestionIndex(questionIndex));
  };
  const handleOverviewClick = () => {
    dispatch(setValue("1"));
  };



  const filteredAnswersLengths = Array.isArray(selectedAnswers) ?
    selectedAnswers.map((answers, index) => {
      if (!answers) {
        return 0;
      }
      return answers.filter(answer => answer && answer.isReviewed === 'answered').length;
    }) : [];

  const answeredPercentage = (filteredAnswersLengths.reduce((acc, count) => acc + count, 0) / totalQue) * 100;
  const handleNext=()=>{
    dispatch(setValue("2"));
  }

  return (
    <>
      <div className="App">
        <div className="test-start-header">
          <ToastContainer />
          <div className="test-container">
            <div className='row middle-info-item progress-flex'>
              <div className='col-xl-8'>
                <div className='practice-test-left'>
                  <div className='practice-test-name'>
                    <img src={testSheet} alt="Test Sheet Icon" className='test-sheet-img' />
                    <h1>{testName}</h1>
                  </div>
                  <span className='cognizant-test'><img src={testScore} alt="" /> Test score: <div>{totalScore}</div></span>
                  <span className='cognizant-test no-border-test'><img src={CognizantColor} alt="" />{company}</span>
                </div>
              </div>
              <div className='col-xl-4'>
                <div className='practice-test-right'>
                  <span className='clock-icon-text'><img src={clockIcon} alt="" /> {formatTime(remainingTime)}</span>
                  <Button className='btn-overview' onClick={handleOverviewClick}>Overview</Button>
                  <Button className='btn-end-test' onClick={handleReview}>End Test</Button>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-lg-12'>
                <div className='progress-test'>
                  <LinearProgress
                    style={{ backgroundColor: '#E8E9EE' }}
                    className='test-line-progress'
                    value={answeredPercentage}
                    thickness={8}
                    determinate
                    size="lg"
                    variant="solid"
                  />
                  <span>{answeredPercentage}%</span>
                </div>
              </div>
            </div>
          </div>
          <div className='user-img-test'>

            {!cameraStarted ? (
              <img src={userTestImage} alt="Test Sheet Icon" />
            ) : (
              <Webcam
                audio={false}
                className="userimage"
                screenshotFormat="image/jpeg"
                videoConstraints={videoConstraints}
                ref={videoRef}
              />
            )}
          </div>
        </div>
        <div className='test-tabs'>
          <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChange} aria-label="lab API tabs example" variant="scrollable">
                  <Tab className='tab-text' label={`Overview (${totalQuestions})`} value="1" key="overview" />
                  {testData?.map((test, index) => (
                    <Tab className='tab-text' label={`${test?.skill_id?.skills} (${test?.easy_question?.length + test?.medium_question?.length + test?.hard_question?.length})`} value={(index + 2).toString()} key={index.toString()} />
                  ))}
                </TabList>

              </Box>
              <TabPanel className='tab-pannel-box' value="1">
                <h2 className='test-overview-heading'>Test Overview</h2>
                {testData?.map((test, indexNew) => (
                  <div className='test-module' key={indexNew}>
                    <div className='test-name-pannel'>
                      <h3>{test?.skill_id?.skills} <span>({test?.easy_question?.length + test?.medium_question?.length + test?.hard_question?.length})</span></h3>

                      <div className='total-marks'><img className='test-scoe-icon' src={testScore} alt="" /> Total Marks: <span>
                        {(test?.easy_question?.flatMap(question => question.score).reduce((acc, curr) => {

                          const digits = curr.toString().split('').map(Number);
                          return acc + digits.reduce((a, b) => a + b, 0);
                        }, 0) + (test?.medium_question?.flatMap(question => question.score).reduce((acc, curr) => {

                          const digits = curr.toString().split('').map(Number);
                          return acc + digits.reduce((a, b) => a + b, 0);
                        }, 0) + (test?.hard_question?.flatMap(question => question.score).reduce((acc, curr) => {

                          const digits = curr.toString().split('').map(Number);
                          return acc + digits.reduce((a, b) => a + b, 0);
                        }, 0))))}
                      </span></div>
                    </div>
                    <Accordion defaultExpanded className='accordion-pannel'>
                      <AccordionSummary
                        className='pannel-revers'
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        <Typography className='expand-more'>

                          <span className='question-name'>
                            {test?.question_type && (test?.question_type === 'MCQ' || test?.question_type === 'Programming' || test?.question_type === 'SQL' || test?.question_type === 'HTML') ? (
                              <>
                                {test?.question_type} ({(test?.easy_question?.filter(question => question.type === test?.question_type)?.length || 0) +
                                  (test?.medium_question?.filter(question => question.type === test?.question_type)?.length || 0) +
                                  (test?.hard_question?.filter(question => question.type === test?.question_type)?.length || 0)})
                              </>
                            ) : (
                              <>Question type is not MCQ, Programming, SQL, or HTML</>
                            )}
                          </span>
                          <div className='skills-score'> Skill assessment score: <span>
                     
                            {(test?.easy_question?.flatMap(question => question.score).reduce((acc, curr) => {

                              const digits = curr.toString().split('').map(Number);
                              return acc + digits.reduce((a, b) => a + b, 0);
                            }, 0) + (test?.medium_question?.flatMap(question => question.score).reduce((acc, curr) => {

                              const digits = curr.toString().split('').map(Number);
                              return acc + digits.reduce((a, b) => a + b, 0);
                            }, 0) + (test?.hard_question?.flatMap(question => question.score).reduce((acc, curr) => {

                              const digits = curr.toString().split('').map(Number);
                              return acc + digits.reduce((a, b) => a + b, 0);
                            }, 0))))}
                          </span></div>
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          {(() => {
                            let totalQuestionsCount = 0;
                            const allQuestions = ['easy_question', 'medium_question', 'hard_question'].flatMap((difficulty, difficultyIndex) => (
                              test[difficulty].map((question, index) => {
                                totalQuestionsCount++;
                                return {
                                  question,
                                  index: String(difficultyIndex + index)
                                };
                              })
                            ));

                            return allQuestions.map((questionObject, index) => (
                              <div className='question-list' key={index}>
                                <div className='question' style={{ cursor: 'pointer' }} onClick={(event) => handleChangeNew(event, String(indexNew + 2), index)}>
                                  {`Q${index + 1}. ${questionObject.question.question}`}
                                </div>
                                <div className='question-check-style'>
                                  <span className='question-check-style-number'>{questionObject.question.score}</span>
                                  <FormControl className='uncheck-overview'>
                                    <Checkbox
                                      style={checkboxStyle}
                                      checked={selectedAnswers &&
                                        selectedAnswers[indexNew] &&
                                        selectedAnswers[indexNew][index] &&
                                        selectedAnswers[indexNew][index].isReviewed === 'answered'}
                                      disabled
                                    />
                                    <label className='uncheck-box-icon'></label>
                                  </FormControl>
                                </div>
                              </div>
                            ));
                          })()}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                ))}
                <div className='footer-btn-next'>
                  <div className='footer-btn-inner'>
                    <Button onClick={handleNext}>Next</Button>
                  </div>
                </div>
                <div className='footer-logo footer-bg-white'>
                  <img src={WhoWeAreImgpowerd} alt="" className='who-wer-are-p-img' />
                </div>
              </TabPanel>

              <TabPanel className='tab-panel-box start-question-answer' value={`${value?.toString()}`}>
                {testData && testData.length > 0 && testData[value - 2] && (
                  <div className='question-main-test'>
                    {showQuestions && (
                      <div className='question-left'>
                        <div className='question-heading'>
                          <h2>
                            Questions{' '}
                            <Button data-bs-toggle="modal" data-bs-target="#QuestinHelp" className='questinhelp'>
                              <img src={questionOverview} alt="" />
                            </Button>
                          </h2>
                          <div className='close-btn-mobile' onClick={handleMobile}>
                            <img src={closeIconQuestion} alt="" />
                          </div>
                        </div>
                        <div className='current-review-answer'>
                          <span><div className='current-box'></div>Current</span>
                          <span><div className='review-box'></div>For Review</span>
                          <span><div className='answered-box'></div>Answered</span>
                        </div>
                        <div className='question-heading'>
                          <h3>{testData[value - 2]?.question_type && (testData[value - 2]?.question_type === 'MCQ' || testData[value - 2]?.question_type === 'Programming' || testData[value - 2]?.question_type === 'SQL' || testData[value - 2]?.question_type === 'HTML') ? (
                            <>
                              {testData[value - 2]?.question_type} ({(testData[value - 2]?.easy_question?.filter(question => question.type === testData[value - 2]?.question_type)?.length || 0) +
                                (testData[value - 2]?.medium_question?.filter(question => question.type === testData[value - 2]?.question_type)?.length || 0) +
                                (testData[value - 2]?.hard_question?.filter(question => question.type === testData[value - 2]?.question_type)?.length || 0)})
                            </>
                          ) : (
                            <>Question type is not MCQ, Programming, SQL, or HTML</>
                          )}</h3>

                          <ButtonGroup className='grp-btn' variant="contained" aria-label="Basic button group">
                            {[...Array((testData[value - 2]?.easy_question?.filter(question => question.type === testData[value - 2]?.question_type)?.length || 0) +
                              (testData[value - 2]?.medium_question?.filter(question => question.type === testData[value - 2]?.question_type)?.length || 0) +
                              (testData[value - 2]?.hard_question?.filter(question => question.type === testData[value - 2]?.question_type)?.length || 0)).keys()].map((_, questionIndex) => {
                                let reviewClassName = '';
                                if (
                                  selectedAnswers &&
                                  selectedAnswers[value - 2] &&
                                  selectedAnswers[value - 2][questionIndex] &&
                                  selectedAnswers[value - 2][questionIndex].isReviewed === 'review'
                                ) {
                                  reviewClassName = 'review-question';
                                } else if (
                                  selectedAnswers &&
                                  selectedAnswers[value - 2] &&
                                  selectedAnswers[value - 2][questionIndex] &&
                                  selectedAnswers[value - 2][questionIndex].isReviewed === 'answered'
                                ) {
                                  reviewClassName = 'answered-question';
                                } else {
                                  reviewClassName = 'default-btn';
                                }

                                const currentQuestionClass = currentQuestionIndex === questionIndex ? 'current-question' : '';

                                return (
                                  <Button
                                    key={questionIndex}
                                    className={`${reviewClassName} ${currentQuestionClass}`}
                                    onClick={() => handleButtonClick(questionIndex)}
                                  >
                                    {questionIndex + 1}
                                  </Button>
                                );
                              })}
                          </ButtonGroup>

                        </div>
                        <div className='left-nav-logo'>
                          <img src={WhoWeAreImgpowerd} alt="" className='left-nav-logo-img' />
                        </div>
                      </div>
                    )}
                    <div className='question-right' ref={el => {
                      //  if (el) {
                      //    el.style.paddingTop = '35px';
                      //  }
                    }}>
                      {['easy_question', 'medium_question', 'hard_question'].map((difficulty) => (
                        testData[value - 2][difficulty].map((question, index) => {
                          const questionIndex = overallQuestionIndex++; // Calculate the overall question index and increment
                          return (
                            <div className='question-start' key={`${difficulty}-${index}`}>
                              <Box className='question-no-start' ref={el => questionsRef.current[questionIndex] = el}>
                                <div className='max-score-reset-btn'>
                                  <Button className='mobile-questin-view' onClick={handleMobile}>
                                    <img src={mobileViewQs} alt="" />
                                  </Button>
                                  <div className='d-flex'>
                                    <Button className='reset-btn' onClick={() => handleAnswerReset(value - 2, index)}>
                                      <img src={resetAns} alt="" />Reset Answer
                                    </Button>
                                    <div className='maxscore-btn'>
                                      <img src={maxScore} alt="" />Max score: {question.score}
                                    </div>
                                  </div>
                                </div>
                                <FormControl>
                                  <FormLabel className='question-label' id="radio-buttons-group-label">
                                    <img src={questionMark} alt="" /> {`Q${questionIndex + 1}. ${question.question}?`}
                                  </FormLabel>
                                  {/* <RadioGroup
                                    aria-labelledby="radio-buttons-group-label"
                                    value={
                                      selectedAnswers &&
                                        selectedAnswers[value - 2] &&
                                        selectedAnswers[value - 2][questionIndex] &&
                                        selectedAnswers[value - 2][questionIndex].answerIndex !== undefined
                                        ? selectedAnswers[value - 2][questionIndex].answerIndex
                                        : null
                                    }
                                    onChange={(event) => {                               
                                      handleAnswerSelection(
                                        value - 2,
                                        questionIndex,
                                        parseInt(event.target.value),
                                        setAnswerId(question.answersObjectArray[event.target.value]?.optionId)
                                      );
                                    }}
                                    name="radio-buttons-group"
                                    className='radio-btn'
                                  >
                                    {question.answersObjectArray.map((answer, answerIndex) => (
                                      <FormControlLabel
                                        key={`${difficulty}-${index}-${answerIndex}`}
                                        value={answerIndex}
                                        control={<Radio />}
                                        label={answer.option}
                                      />
                                    ))}
                                  </RadioGroup> */}
                                  {question.answersObjectArray.html ? (

                                    <div className="sun-editor-editable">
                                      {question.answersObjectArray.map((answer, answerIndex) => (
                                        <div key={answerIndex} dangerouslySetInnerHTML={{ __html: answer?.html }} />
                                      ))}
                                    </div>

                                  ) : (
                                    <RadioGroup
                                      aria-labelledby="radio-buttons-group-label"
                                      value={
                                        selectedAnswers &&
                                          selectedAnswers[value - 2] &&
                                          selectedAnswers[value - 2][questionIndex] &&
                                          selectedAnswers[value - 2][questionIndex].answerIndex !== undefined
                                          ? selectedAnswers[value - 2][questionIndex].answerIndex
                                          : null
                                      }
                                      onChange={(event) => {
                                        handleAnswerSelection(
                                          value - 2,
                                          questionIndex,
                                          parseInt(event.target.value),
                                          setAnswerId(question.answersObjectArray[event.target.value]?.optionId)
                                        );
                                      }}
                                      name="radio-buttons-group"
                                      className='radio-btn'
                                    >
                                      {question.answersObjectArray.map((answer, answerIndex) => (
                                        <FormControlLabel
                                          key={`${difficulty}-${index}-${answerIndex}`}
                                          value={answerIndex}
                                          control={<Radio />}
                                          label={answer.option}
                                        />
                                      ))}
                                    </RadioGroup>
                                  )}

                                </FormControl>
                                <div className='footer-question-list'>
                                  <Button
                                    onClick={(event) => handleAnswerSelection(value - 2, questionIndex, selectedAnswers?.[value - 2]?.[questionIndex]?.answerIndex ?? null, 'review')}
                                    disabled={selectedAnswers?.[value - 2]?.[questionIndex]?.isReviewed === 'review'}
                                    className='mark-review-btn'
                                  >
                                    {selectedAnswers && selectedAnswers[value - 2] && selectedAnswers[value - 2][questionIndex] && selectedAnswers[value - 2][questionIndex].isReviewed === 'review'
                                      ? "Reviewed"
                                      : "Mark for review"
                                    }
                                  </Button>

                                  <div>
                                    <Button
                                      onClick={(event) => {
                                        const newValue = {
                                          value: value - 2,
                                          questionIndex,
                                          selectedAnswer: selectedAnswers?.[value - 2]?.[questionIndex]?.answerIndex ?? null,
                                          status: 'answered'
                                        };

                                        submitAnswer(answerId, question._id, selectedAnswers?.[value - 2]?.[questionIndex]?.isReviewed, newValue);
                                      }}
                                      className='save-btn'
                                      disabled={selectedAnswers?.[value - 2]?.[questionIndex]?.isReviewed === 'answered'}
                                    >
                                      {selectedAnswers?.[value - 2]?.[questionIndex]?.isReviewed === 'answered' ? "Submitted" : "Save"}
                                    </Button>

                                  </div>
                                </div>
                              </Box>
                            </div>
                          );
                        })
                      ))}
                    </div>
                  </div>
                )}
              </TabPanel>
            </TabContext>
          </Box>
        </div>
      </div>
      <div className="modal fade overview-question-list" id="QuestinHelp" tabIndex="-1" aria-labelledby="QuestinHelp" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            <div className="modal-body">
              {testData && testData.length > 0 && testData[value - 2] && (
                <div>
                  {['easy_question', 'medium_question', 'hard_question'].map((difficulty) => (
                    testData[value - 2][difficulty].map((question, index) => {
                      const questionIndexNew = overallQuestionIndexNew++;
                      return (
                        <>
                          <p>{`Q${questionIndexNew + 1}. ${question.question}?`}</p>
                        </>
                      );
                    })
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Overview


// const dummyAudioFile = 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3';


// const dummyVideoFile = 'https://s3.amazonaws.com/codecademy-content/courses/React/react_video-fast.mp4';



{/* Question Number Two */ }
{/* <div className='question-start'>
                      <Box className='question-no-start'>
                        <div className='max-score-reset-btn'>
                          <Button className='mobile-questin-view' onClick={handleMobile}><img src={mobileViewQs} alt="" /></Button>
                          <div className='d-flex'>
                            <Button className='reset-btn'><img src={resetAns} alt="" />Reset Answer</Button>
                            <div className='maxscore-btn'><img src={questionMarkStar} alt="" />Max score: 2</div>
                          </div>
                        </div>
                        <FormControl>
                          <FormLabel className='question-label' id="radio-buttons-group-label"><img src={questionMark} alt="" /> Q.2 React.js is written in which of the following language?</FormLabel>
                          <RadioGroup
                            aria-labelledby="radio-buttons-group-label"
                            defaultValue="1"
                            name="radio-buttons-group"
                            className='radio-btn'
                          >
                            <FormControlLabel value="1" control={<Checkbox defaultChecked />} label="Open source JavaScript back end library" />
                            <FormControlLabel value="2" control={<Checkbox />} label="JavaScript front end library to create a database" />
                            <FormControlLabel value="3" control={<Checkbox />} label="Free and Open source JavaScript front end library" />
                            <FormControlLabel value="4" control={<Checkbox />} label="None of the mentioned" />
                          </RadioGroup>
                        </FormControl>
                        <div className='footer-question-list'>
                          <Button className='mark-review-btn'>Mark for review</Button>
                          <div>
                            <Button className='prev-btn'>Previous</Button>
                            <Button className='save-btn'>Save</Button>
                          </div>
                        </div>
                      </Box>
                    </div> */}

{/* Question Number Three */ }
{/* <div className='question-start'>
                      <Box className='question-no-start'>
                        <div className='max-score-reset-btn'>
                          <Button className='mobile-questin-view' onClick={handleMobile}><img src={mobileViewQs} alt="" /></Button>
                          <div className='d-flex'>
                            <Button className='reset-btn'><img src={resetAns} alt="" />Reset Answer</Button>
                            <div className='maxscore-btn'><img src={questionMarkStar} alt="" />Max score: 2</div>
                          </div>
                        </div>
                        <FormControl>
                          <FormLabel className='question-label' id="radio-buttons-group-label"><img src={questionMark} alt="" /> Q.3 Audio?</FormLabel>

                          <audio className='audio-control' controls id="audioPlayer" src={dummyAudioFile} />

                          <RadioGroup
                            aria-labelledby="radio-buttons-group-label"
                            defaultValue="1"
                            name="radio-buttons-group"
                            className='radio-btn'
                          >
                            <FormControlLabel value="1" control={<Radio />} label="Audio" />
                            <FormControlLabel value="2" control={<Radio />} label="Embedded Link" />
                          </RadioGroup>
                        </FormControl>
                        <div className='footer-question-list'>
                          <Button className='mark-review-btn'>Mark for review</Button>
                          <div>
                            <Button className='prev-btn'>Previous</Button>
                            <Button className='save-btn'>Save</Button>
                          </div>
                        </div>
                      </Box>
                    </div> */}

{/* Question Number Four */ }
{/* <div className='question-start'>
                      <Box className='question-no-start'>
                        <div className='max-score-reset-btn'>
                          <Button className='mobile-questin-view' onClick={handleMobile}><img src={mobileViewQs} alt="" /></Button>
                          <div className='d-flex'>
                            <Button className='reset-btn'><img src={resetAns} alt="" />Reset Answer</Button>
                            <div className='maxscore-btn'><img src={questionMarkStar} alt="" />Max score: 2</div>
                          </div>
                        </div>
                        <FormControl>
                          <FormLabel className='question-label' id="radio-buttons-group-label"><img src={questionMark} alt="" /> Q.4 Video</FormLabel>

                          <video className='video-control' src={dummyVideoFile} controls autoPlay />

                          <RadioGroup
                            aria-labelledby="radio-buttons-group-label"
                            defaultValue="1"
                            name="radio-buttons-group"
                            className='radio-btn'
                          >
                            <FormControlLabel value="1" control={<Radio />} label="Audio" />
                            <FormControlLabel value="2" control={<Radio />} label="Embedded Link" />
                          </RadioGroup>
                        </FormControl>
                        <div className='footer-question-list'>
                          <Button className='mark-review-btn'>Mark for review</Button>
                          <div>
                            <Button className='prev-btn'>Previous</Button>
                            <Button className='save-btn'>Save</Button>
                          </div>
                        </div>
                      </Box>
                    </div> */}

{/* Question Number Five */ }
{/* <div className='question-start'>
                      <Box className='question-no-start'>
                        <div className='max-score-reset-btn'>
                          <Button className='mobile-questin-view' onClick={handleMobile} ><img src={mobileViewQs} alt="" /></Button>
                          <div className='d-flex'>
                            <Button className='reset-btn'><img src={resetAns} alt="" />Reset Answer</Button>
                            <div className='maxscore-btn'><img src={questionMarkStar} alt="" />Max score: 2</div>
                          </div>
                        </div>
                        <FormControl>
                          <FormLabel className='question-label' id="radio-buttons-group-label"><img src={questionMark} alt="" /> Q.5 Image</FormLabel>

                          <div className='questionimg'>
                            <img src={questionImgList} alt="" />
                          </div>

                          <RadioGroup
                            aria-labelledby="radio-buttons-group-label"
                            defaultValue="1"
                            name="radio-buttons-group"
                            className='radio-btn'
                          >
                            <FormControlLabel value="1" control={<Radio />} label="Audio" />
                            <FormControlLabel value="2" control={<Radio />} label="Embedded Link" />
                          </RadioGroup>
                        </FormControl>
                        <div className='footer-question-list'>
                          <Button className='mark-review-btn'>Mark for review</Button>
                          <div>
                            <Button className='prev-btn'>Previous</Button>
                            <Button className='save-btn'>Save</Button>
                          </div>
                        </div>
                      </Box>
                    </div> */}